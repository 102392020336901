import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip as ChartTooltip } from 'recharts';

import { Flex } from 'rebass';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import './App.css';
import infoLogo from './images/info.svg';
const Web3 = require('web3');
const BandChain = require('@bandprotocol/bandchain.js');

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

const getEthPrice = async () => {
  const endpoint = 'https://poa-api.bandchain.org';

  const bandchain = new BandChain(endpoint);
  const price = await bandchain.getReferenceData(['ETH/USD']);
  return price[0].rate;
};

function getTotalTxsStats(txs, type) {
  if (txs.length > 0) {
    if (type == 1) {
      txs = txs.filter((tx) => tx.isError == 0);
    }
    if (type == 2) {
      txs = txs.filter((tx) => tx.isError != 0);
    }
    let stats = { used: 0, price: 0, count: 0, cost: 0 };
    txs.forEach((row) => {
      stats.count += 1;
      stats.used += Number(row.gasUsed);
      stats.price += Number(row.gasPrice);
      stats.cost += Number(row.gasPrice) * Number(row.gasUsed);
    });
    return stats;
  } else {
    return { count: 0, used: 0, price: 0, cost: 0 };
  }
}

function getRows(txs) {
  return Object.entries(
    txs.reduce((a, e) => {
      if (a[e.to]) {
        a[e.to] = {
          gasUsed: Number(a[e.to].gasUsed) + Number(e.gasUsed),
          gasPrice: a[e.to].gasPrice + e.gasPrice,
          count: a[e.to].count + 1,
        };
      } else {
        a[e.to] = { gasUsed: Number(e.gasUsed), gasPrice: e.gasPrice, count: 1 };
      }
      return { ...a };
    }, {})
  )
    .map(([address, value]) => ({
      address,
      gasPrice: value.gasPrice,
      gasUsed: value.gasUsed,
      count: value.count,
    }))
    .sort((a, b) => (a.count > b.count ? -1 : 1));
}

const getAddress = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    window.ethereum.enable();
    let accounts = {};
    while (Object.keys(accounts).length === 0) {
      accounts = await window.web3.eth.getAccounts();
    }
    return accounts[0];
  } else {
    alert('Web3 not detected');
    console.log('Cannot connect to Web3');
  }
};

const renderHistoricalGasPrices = (txs) => {
  return (
    <LineChart width={1200} height={300} data={txs} margin={{ top: 20 }}>
      <Line type="monotone" dataKey="gasPrice" stroke="#5369ff" />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="timeStamp" />
      <YAxis />
      <ChartTooltip />
    </LineChart>
  );
};

function TxStatsTable(txs, ethPrice) {
  let totalTxsStats = getTotalTxsStats(txs, 0);
  let successTxsStats = getTotalTxsStats(txs, 1);
  let failTxsStats = getTotalTxsStats(txs, 2);
  return (
    <TableContainer className={useStyles().table} component={Paper}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Transaction Type</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Average Gas Cost (gwei)</TableCell>
            <TableCell align="right">Est. Total Cost (ETH)</TableCell>
            <TableCell align="right">Est. Total Cost (USD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="txCount">
            <TableCell align="left">All Transactions</TableCell>
            <TableCell align="right">{totalTxsStats.count || 0}</TableCell>
            <TableCell align="right">
              {(totalTxsStats.price / totalTxsStats.count).toFixed(4)}
            </TableCell>
            <TableCell align="right">{(totalTxsStats.cost / 1e9).toFixed(4)}</TableCell>
            <TableCell align="right">
              {((totalTxsStats.cost / 1e9) * ethPrice).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow key="txSuccess">
            <TableCell align="left">Successful Transactions</TableCell>
            <TableCell align="right">{successTxsStats.count || 0}</TableCell>
            <TableCell align="right">
              {(successTxsStats.price / successTxsStats.count).toFixed(4)}
            </TableCell>
            <TableCell align="right">{(successTxsStats.cost / 1e9).toFixed(4)}</TableCell>
            <TableCell align="right">
              {((successTxsStats.cost / 1e9) * ethPrice).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow key="txFailed">
            <TableCell align="left">Failed Transactions</TableCell>
            <TableCell align="right">{failTxsStats.count || 0}</TableCell>
            <TableCell align="right">
              {(failTxsStats.price / failTxsStats.count).toFixed(4)}
            </TableCell>
            <TableCell align="right">{(failTxsStats.cost / 1e9).toFixed(4)}</TableCell>
            <TableCell align="right">{((failTxsStats.cost / 1e9) * ethPrice).toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const rendergasByDestination = (txs) => {
  const rows = getRows(txs);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Address</TableCell>
            <TableCell align="right">Number of Transactions</TableCell>
            <TableCell align="right">Average Gas Price (gwei)</TableCell>
            <TableCell align="right">Average Gas Used</TableCell>
            <TableCell align="right">Total Gas Used</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0, 10).map((row, idx) => (
            <TableRow key={row.address}>
              <TableCell component="th" scope="row">
                {idx + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                <a href={`https://etherscan.io/address/` + row.address}>{row.address}</a>
              </TableCell>
              <TableCell align="right">{row.count}</TableCell>
              <TableCell align="right">{(row.gasPrice / row.count).toFixed(4)}</TableCell>
              <TableCell align="right">{(row.gasUsed / row.count).toFixed(4)}</TableCell>
              <TableCell align="right">{row.gasUsed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function App() {
  const [address, setAddress] = useState('');
  const [txs, setTxs] = useState([]);
  const [ethPrice, setEthPrice] = useState(0);
  useEffect(() => {
    async function fetchData() {
      setAddress(await getAddress());
      setEthPrice(await getEthPrice());
      window.ethereum.on('accountsChanged', function (accounts) {
        setAddress(accounts[0]);
      });
    }
    fetchData();
  }, []);
  useEffect(() => {
    const fetchTxs = async () => {
      const result = await axios(
        `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&sort=asc&apikey=S848Q8IF3BTZ6M7VC8F1IMYMHN3RK6JE7I`
      );
      let res = result.data.result;
      if (Array.isArray(res)) {
        let fromTxs = res.filter(function (tx) {
          return tx['from'] === address.toLowerCase();
        });
        fromTxs.map((tx) => {
          tx.gasPrice = tx.gasPrice / 1e9;
          var d = new Date(tx.timeStamp * 1000);
          tx.timeStamp = d.toUTCString();
        });
        setTxs(fromTxs);
      }
    };

    fetchTxs();
  }, [address]);
  return (
    <div style={{ marginLeft: '10px', marginRight: '10px' }}>
      <h1>txn.finance</h1>
      <p style={{ fontSize: '20px', margin: 0, marginBottom: 20 }}>
        <b>Address</b>: {address} (
        <a
          href={`https://etherscan.io/address/${address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Etherscan
        </a>
        )
      </p>
      <Tooltip
        placement="right"
        title="ETH price data provided by Band Oracle. Aggregated from CoinGecko, CryptoCompare, CoinMarketCap, Binance, Huobi Pro, Kraken, Bitfinex, and Bittrex"
      >
        <Flex justifyContent="space-between" width="110px" marginBottom="20px">
          <h2 style={{ margin: 0 }}>Summary</h2>
          <img src={infoLogo} style={{ width: 15 }} alt="tooltip" />
        </Flex>
      </Tooltip>
      <p style={{ fontSize: '20px', margin: 0 }}>
        <b>Current ETH Price</b>: {ethPrice.toFixed(2)} USD
      </p>
      <p style={{ fontSize: '20px', margin: 0 }}>
        <b>Transaction Count</b>:{' '}
        {(() => {
          let txStats = getTotalTxsStats(txs, 0);
          return txStats.count;
        })()}{' '}
        (
        {(() => {
          let successTxsStats = getTotalTxsStats(txs, 1);
          return successTxsStats.count;
        })()}{' '}
        success /{' '}
        {(() => {
          let failedTxStats = getTotalTxsStats(txs, 2);
          return failedTxStats.count;
        })()}{' '}
        failed)
      </p>
      <p style={{ fontSize: '20px', margin: 0 }}>
        <b>Gas used to date</b>:{' '}
        {(() => {
          let txStats = getTotalTxsStats(txs, 0);
          return txStats.used;
        })()}
      </p>
      <p style={{ fontSize: '20px', margin: 0 }}>
        <b>Amount spent on gas to date</b>:{' '}
        {(() => {
          let txStats = getTotalTxsStats(txs, 0);
          return (txStats.cost / 1e9).toFixed(4);
        })()}{' '}
        ETH (
        {(() => {
          let txStats = getTotalTxsStats(txs, 0);
          return ((txStats.cost / 1e9) * ethPrice).toFixed(2);
        })()}{' '}
        USD)
      </p>
      <p style={{ fontSize: '20px', margin: 0 }}>
        <b>Average historical transaction gas price</b>:{' '}
        {(() => {
          let txStats = getTotalTxsStats(txs, 0);
          return (txStats.price / txStats.count).toFixed(4);
        })()}{' '}
        gwei
      </p>

      <h2>Details</h2>
      <h3>Transaction Statistics</h3>
      {
        <Flex flexDirection justifyContent="left">
          {TxStatsTable(txs, ethPrice)}
        </Flex>
      }
      <h3>Historical Transaction Gas Prices</h3>
      {
        <Flex flexDirection justifyContent="center">
          {renderHistoricalGasPrices(txs)}
        </Flex>
      }
      <h3>Top 10 Most Frequent Destination Addresses</h3>
      {
        <Flex flexDirection justifyContent="center">
          {rendergasByDestination(txs)}
        </Flex>
      }

      <Flex marginTop="20px" marginBottom="20px" alignItems="center" flexDirection="column">
        <Flex fontSize="20px">
          Account and Transaction data provided by the{' '}
          <a style={{ marginLeft: '5px' }} href="https://etherscan.io/apis">
            {' '}
            Etherscan API
          </a>
        </Flex>
        <Flex fontSize="20px">
          Price data powered by{' '}
          <a style={{ marginLeft: '5px' }} href="https://bandprotocol.com">
            Band Protocol
          </a>
        </Flex>
      </Flex>
    </div>
  );
}

export default App;
